import { loadableReady } from '@loadable/component';
import { Logger } from '@utils/logger/logger';
import { getMember } from '@xo-union/store-membership-redux';
import createTabAccessibility from '@xo-union/tab-accessibility';
import { ErrorBoundary } from 'react-error-boundary';

// @ts-check
import 'intersection-observer';
import { Provider as JotaiProvider } from 'jotai';
import React from 'react';
import { hydrate } from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { BrowserRouter } from 'react-router-dom';
import 'requestidlecallback-polyfill';

import { HydrateAtoms } from '@components/hydrate-atoms';
import { NewrelicHelpers } from '@utils/logger/internal/newrelicHelpers';
import { getVisitorId } from '../utils/cookies';
import AnalyticsProvider from './contexts/AnalyticsContext/AnalyticsContext';
import { getJotaiInitialValues } from './jotai/store';
import { setGatekeeper } from './redux/experiments';
import { getPageType } from './redux/page';
import * as settingActions from './redux/settings/actions';
import { transmitImpressions } from './redux/vendorImpressions/thunks';
import { setWindowWidth } from './redux/viewport';
import { setVisitorId } from './redux/visitor';
import routes from './routes/routes';
import createStore from './store';
import ensureAsyncReady from './utils/ensureAsyncReady';
import { reportWebVitals } from './utils/reportWebVitals';

const logError = (error, info) => {
	Logger.error({
		log_message: error.message,
		optionalParams: { info },
		errorToLog: error,
		newRelic: true,
	});
};

const serviceWorker = () => {
	if (typeof navigator !== 'undefined' && 'serviceWorker' in navigator) {
		navigator.serviceWorker.getRegistrations().then((registrations) => {
			for (const registration of registrations) {
				registration.unregister();
			}
		});
	}
};

const parseGatekeeperModules = (url) => {
	if (!url) {
		return null;
	}
	const GATEKEEPER = 'gatekeeper';
	const gateKeeperQueryIndex = url.lastIndexOf(GATEKEEPER);
	if (gateKeeperQueryIndex < 0) {
		return null;
	}
	const nextQueryStringStart = url.substring(gateKeeperQueryIndex).indexOf('&');
	const gateKeeperQuery = url.substring(
		gateKeeperQueryIndex + GATEKEEPER.length + 1,
		nextQueryStringStart > -1
			? gateKeeperQueryIndex + nextQueryStringStart
			: undefined,
	);
	return gateKeeperQuery;
};

if (typeof window !== 'undefined') {
	window.Logger = Logger;
	window.NewrelicHelpers = NewrelicHelpers;

	serviceWorker();

	ensureAsyncReady(routes)
		.then(() => {
			const store = createStore(window.__INITIAL_STATE__); // eslint-disable-line
			const { getState, dispatch } = store;
			const state = getState();
			const pageType = getPageType(state);
			const { membership, vendor } = state;
			const memberId = membership?.member?.id || null;

			dispatch(getMember()); // union hook to initialize membership in redux

			const windowWidth = window.innerWidth;
			dispatch(setWindowWidth(windowWidth));

			// load visitor ID from cookie
			const visitorId = getVisitorId();
			if (visitorId) {
				dispatch(setVisitorId(visitorId));
			}

			const tabAccessbility = createTabAccessibility();
			tabAccessbility.mount();

			const gatekeeperModules = parseGatekeeperModules(window.location.href);
			if (gatekeeperModules) {
				dispatch(setGatekeeper(gatekeeperModules));
			}

			const analyticsProps = { memberId };
			if (pageType === 'storefront') {
				analyticsProps.storefront_completion_status =
					vendor?.vendor?.completionScore;
			}
			analytics?.page(pageType, analyticsProps);

			/**
			 * @callback UnloadListenerCallback
			 */

			/** @type {UnloadListenerCallback} */
			const beforeUnloadListener = () => {
				dispatch(transmitImpressions(1, state.location));
			};

			if (typeof window !== 'undefined') {
				window.onbeforeunload = beforeUnloadListener;
			}

			window?.analytics?.ready(() => {
				settingActions.requestAnonymousId()(dispatch, getState, undefined);
			});

			const initialJotaiState = getJotaiInitialValues(
				window?.__INITIAL_JOTAI_STATE__,
			);

			const App = () => {
				return (
					<ErrorBoundary
						onError={logError}
						fallback={
							<div className="error">
								<div>An Error Occurred</div>
							</div>
						}
					>
						<Provider store={store}>
							<JotaiProvider>
								<HydrateAtoms initialValues={initialJotaiState}>
									<HelmetProvider>
										<AnalyticsProvider
											reduxExperiments={window.__INITIAL_STATE__?.experiments}
										>
											<BrowserRouter>
												{renderRoutes(routes, { pageType })}
												{/* pageType passed as an extra prop on layout */}
											</BrowserRouter>
										</AnalyticsProvider>
									</HelmetProvider>
								</HydrateAtoms>
							</JotaiProvider>
						</Provider>
					</ErrorBoundary>
				);
			};

			loadableReady(() => {
				hydrate(
					<App />,
					document.getElementById('app'), // eslint-disable-line
				);
			});
		})
		.catch((error) => {
			logError(error, {
				name: 'ensureAsyncReady failure in client/index.jsx',
				routes,
			});

			throw error;
		});
}

reportWebVitals();
